import { NumberFormatter } from './NumberFormatter'

export interface SimulatorEntry {
  taxation: number
  propertyValue: number
  furnitureValue: number
  amountBorrowed: number
  interestRate: number
  termOfLoan: number
  annualRent: number
  annualCharges: number
  refundRate: number
}

export class SimulatorHandler {
  entry: SimulatorEntry = {
    taxation: 30,
    propertyValue: 150000,
    furnitureValue: 5000,
    amountBorrowed: 150000,
    interestRate: 1.5,
    termOfLoan: 15,
    annualRent: 5000,
    annualCharges: 1500,
    refundRate: 1,
  }

  MICRO_ABATEMENT_RATIO = 0.172
  DEPRECIATION_PROPERTY_RATIO = 25
  DEPRECIATION_FURNITURE_RATIO = 5
  MAX_MICRO_RENT = 15000

  get isEntryValid(): boolean {
    return (
      this.entry.taxation !== null &&
      this.entry.propertyValue !== null &&
      this.entry.furnitureValue !== null &&
      this.entry.amountBorrowed !== null &&
      this.entry.interestRate !== null &&
      this.entry.termOfLoan !== null &&
      this.entry.annualRent !== null &&
      this.entry.annualCharges !== null &&
      this.entry.refundRate !== null
    )
  }

  get interestRate(): number {
    return this.entry.interestRate / 100
  }

  get taxation(): number {
    return this.entry.taxation / 100
  }

  get rent(): number {
    return this.entry.annualRent
  }

  get abatement(): number {
    return this.rent / 2
  }

  get taxeBaseMicro(): number {
    if (this.entry.annualRent > this.MAX_MICRO_RENT) {
      return 0
    }
    return this.rent - this.abatement
  }

  get annualTaxesMicro(): number {
    if (this.entry.annualRent > this.MAX_MICRO_RENT) {
      return 0
    }
    return (
      this.abatement * this.taxation +
      this.abatement * this.MICRO_ABATEMENT_RATIO
    )
  }

  get depreciationProperty(): number {
    return this.entry.propertyValue / this.DEPRECIATION_PROPERTY_RATIO
  }

  get depreciationFurniture(): number {
    return this.entry.furnitureValue / this.DEPRECIATION_FURNITURE_RATIO
  }

  get depreciation(): number {
    return -this.depreciationProperty - this.depreciationFurniture
  }

  get annualRefund(): number {
    const r = this.interestRate
    const C = this.entry.amountBorrowed
    const n = this.entry.refundRate
    const N = this.entry.termOfLoan * this.entry.refundRate
    const ratio = r / n

    const m = (C * ratio) / (1 - Math.pow(1 + ratio, -N))
    return m * this.entry.refundRate
  }

  get totalRefund(): number {
    return this.annualRefund * this.entry.termOfLoan
  }

  get smoothedInterest(): number {
    return (
      +(this.totalRefund - this.entry.amountBorrowed) / this.entry.termOfLoan ||
      1
    )
  }

  get loanInterest(): number {
    return -this.smoothedInterest
  }

  get charges(): number {
    return -this.entry.annualCharges
  }

  get taxeBaseReal(): number {
    return this.rent + this.depreciation + this.loanInterest + this.charges
  }

  get annualTaxesReal(): number {
    return (
      this.taxeBaseReal * this.MICRO_ABATEMENT_RATIO +
      this.taxeBaseReal * this.taxation
    )
  }

  get taxeBaseUnfurnished(): number {
    return this.rent + this.loanInterest + this.charges
  }

  get annualTaxesUnfurnished(): number {
    return (
      this.taxeBaseUnfurnished * this.taxation +
      this.taxeBaseUnfurnished * this.MICRO_ABATEMENT_RATIO
    )
  }

  get taxes(): number[] {
    if (this.entry.annualRent > this.MAX_MICRO_RENT) {
      return [this.annualTaxesReal, this.annualTaxesUnfurnished].sort(
        (a, b) => a - b,
      )
    } else {
      return [
        this.annualTaxesReal,
        this.annualTaxesMicro,
        this.annualTaxesUnfurnished,
      ].sort((a, b) => a - b)
    }
  }

  get moreFavorableRegime(): string {
    const min = Math.min(...this.taxes)

    if (min === this.annualTaxesUnfurnished) {
      return 'régime revenus fonciers'
    } else if (min === this.annualTaxesMicro) {
      return 'régime LMNP micro'
    } else {
      return 'régime LMNP réel'
    }
  }

  get moreFavorableCostPhrase(): string {
    let phrase
    if (this.moreFavorableCost > 0) {
      phrase = `vous devrez payer ${NumberFormatter.format(
        this.moreFavorableCost,
        NumberFormatter.euroIntegerOptions,
      )} d'impôts`
    } else {
      phrase = `vous n'avez pas d'impôts à payer`
    }
    return phrase
  }

  get moreFavorableCost(): number {
    const min = Math.min(...this.taxes)

    if (min === this.annualTaxesUnfurnished) {
      return this.annualTaxesUnfurnished
    } else if (min === this.annualTaxesMicro) {
      return this.annualTaxesMicro
    } else {
      return this.annualTaxesReal
    }
  }

  get secondLessFavorableEco(): string {
    return NumberFormatter.format(
      Math.max(0, this.taxes[1]) - Math.max(0, this.taxes[0]),
      NumberFormatter.euroIntegerOptions,
    )
  }

  get secondLessFavorable(): string {
    if (this.taxes[1] === this.annualTaxesUnfurnished) {
      return 'régime revenus fonciers'
    } else if (this.taxes[1] === this.annualTaxesMicro) {
      return 'régime LMNP micro'
    } else {
      return 'régime LMNP réel'
    }
  }

  get lessFavorableEco(): string {
    return NumberFormatter.format(
      Math.max(0, this.taxes[2]) - Math.max(0, this.taxes[0]),
      NumberFormatter.euroIntegerOptions,
    )
  }

  get lessFavorable(): string {
    if (this.taxes[2] === this.annualTaxesUnfurnished) {
      return 'régime revenus fonciers'
    } else if (this.taxes[2] === this.annualTaxesMicro) {
      return 'régime LMNP micro'
    } else {
      return 'régime LMNP réel'
    }
  }
}
