

































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { SimulatorHandler } from './SimulatorHandler'
import { NumberFormatter } from './NumberFormatter'
import { KeyLabel } from '@/model/Project'

export interface Simulator {
  taxation: number
  propertyValue: number
  furnitureValue: number
  amountBorrowed: number
  interestRate: number
  termOfLoan: number
  annualRent: number
  annualCharges: number
}

@Component({})
export default class ViewSimulator extends Vue {
  simulatorHandler = new SimulatorHandler()
  showResult = false

  launch() {
    this.showResult = true
  }

  isNegative(number: number) {
    return number <= 0
  }

  isPositive(number: number) {
    return number >= 0
  }

  refundRates: KeyLabel[] = [
    { key: 1, label: `Annuel` },
    { key: 12, label: `Mensuel` },
  ]

  taxations: KeyLabel[] = [
    {
      key: 0,
      label: '0 %',
    },
    {
      key: 11,
      label: '11 %',
    },
    {
      key: 20,
      label: '20 %',
    },
    {
      key: 30,
      label: '30 %',
    },
    {
      key: 41,
      label: '41 %',
    },
    {
      key: 45,
      label: '45 %',
    },
  ]

  isBest(cost: number) {
    return this.simulatorHandler.taxes[0] === cost
  }

  get abatement(): string {
    return NumberFormatter.format(
      this.simulatorHandler.abatement,
      NumberFormatter.euroIntegerOptions,
    )
  }

  get maxMicroRent(): string {
    return NumberFormatter.format(
      this.simulatorHandler.MAX_MICRO_RENT,
      NumberFormatter.euroIntegerOptions,
    )
  }

  get taxeBaseMicro(): string {
    return NumberFormatter.format(
      this.simulatorHandler.taxeBaseMicro < 0
        ? 0
        : this.simulatorHandler.taxeBaseMicro,
      NumberFormatter.euroIntegerOptions,
    )
  }

  get annualTaxesMicro(): string {
    return NumberFormatter.format(
      this.simulatorHandler.annualTaxesMicro,
      NumberFormatter.euroIntegerOptions,
    )
  }

  get rent(): string {
    return NumberFormatter.format(
      this.simulatorHandler.rent,
      NumberFormatter.euroIntegerOptions,
    )
  }

  get depreciation(): string {
    return NumberFormatter.format(
      this.simulatorHandler.depreciation,
      NumberFormatter.euroIntegerOptions,
    )
  }

  get loanInterest(): string {
    return NumberFormatter.format(
      this.simulatorHandler.loanInterest,
      NumberFormatter.euroIntegerOptions,
    )
  }

  get charges(): string {
    return NumberFormatter.format(
      this.simulatorHandler.charges,
      NumberFormatter.euroIntegerOptions,
    )
  }

  get taxeBaseReal(): string {
    return NumberFormatter.format(
      this.simulatorHandler.taxeBaseReal < 0
        ? 0
        : this.simulatorHandler.taxeBaseReal,
      NumberFormatter.euroIntegerOptions,
    )
  }
  get annualTaxesReal(): string {
    const annualTaxes =
      this.simulatorHandler.annualTaxesReal < 0
        ? 0
        : this.simulatorHandler.annualTaxesReal
    return NumberFormatter.format(
      annualTaxes,
      NumberFormatter.euroIntegerOptions,
    )
  }

  get taxeBaseUnfurnished(): string {
    return NumberFormatter.format(
      this.simulatorHandler.taxeBaseUnfurnished < 0
        ? 0
        : this.simulatorHandler.taxeBaseUnfurnished,
      NumberFormatter.euroIntegerOptions,
    )
  }

  get annualTaxesUnfurnished(): string {
    const annualTaxes =
      this.simulatorHandler.annualTaxesUnfurnished < 0
        ? 0
        : this.simulatorHandler.annualTaxesUnfurnished
    return NumberFormatter.format(
      annualTaxes,
      NumberFormatter.euroIntegerOptions,
    )
  }

  get zero(): string {
    return NumberFormatter.format(0, NumberFormatter.euroIntegerOptions)
  }
}
